var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTable',{staticClass:"table-shadow",attrs:{"headers":_vm.headers,"loading":_vm.loading,"paginate":true,"items":_vm.items,"sort-by":"id_band"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Lista de Produto Modelo")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('Filtro',{on:{"selectedFilters":function($event){_vm.getDataTable((_vm.filters = $event))}}})],1)]},proxy:true},{key:"item.id_band",fn:function(ref){
var item = ref.item;
return [_c('BaseSegmento',{attrs:{"segmento":item.id_band}})]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusFormated(item).color,"dark":""}},[_vm._v(" "+_vm._s(_vm.statusFormated(item).text)+" ")])]}},{key:"item.preco_tabela",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.preco_tabela))+" ")]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","color":"red","dark":"","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-trash-can")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }