<template>
  <BaseTable
    :headers="headers"
    :loading="loading"
    :paginate="true"
    :items="items"
    sort-by="id_band"
    class="table-shadow"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Lista de Produto Modelo</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <Filtro @selectedFilters="getDataTable((filters = $event))" />
      </v-toolbar>
    </template>

    <template v-slot:[`item.id_band`]="{ item }">
      <BaseSegmento :segmento="item.id_band" />
    </template>

    <template v-slot:[`item.ativo`]="{ item }">
      <v-chip :color="statusFormated(item).color" dark>
        {{ statusFormated(item).text }}
      </v-chip>
    </template>

    <template v-slot:[`item.preco_tabela`]="{ item }">
      {{ item.preco_tabela | BrazilianCurrency }}
    </template>

    <template v-slot:[`item.acoes`]="{ item }">
      <v-btn @click="deleteItem(item)" icon class="ml-2" color="red" dark small>
        <v-icon color="red">mdi-trash-can</v-icon>
      </v-btn>
    </template>
  </BaseTable>
</template>

<script>
import campanha from "@/services/http/campanhaService";
import BaseTable from "@/components/shared/NewBaseTable";
import BaseSegmento from "@/components/shared/BaseSegmento";
import Filtro from "./Filtro";
// import serviceParametros from "@/services/parametros";
import currencyMixin from "@/mixins/currencyMixin";

export default {
  name: "Tabela",

  components: {
    BaseTable,
    BaseSegmento,
    Filtro
  },

  mixins: [currencyMixin],

  data() {
    return {
      filters: {},

      headers: [
        { text: "Segmento", value: "id_band" },
        { text: "descricao", value: "descricao_modelo" },
        { text: "Linha", value: "linha" },
        { text: "Ano", value: "ano_modelo" },
        { text: "Preço", value: "preco_tabela", align: "right" },
        { text: "Status", value: "ativo" },
        { text: "Ações", value: "acoes" }
      ],
      items: [],
      loading: false
    };
  },
  methods: {
    statusFormated(metaFuncao) {
      return {
        color: metaFuncao.ativo === "S" ? "green" : "red",
        text: metaFuncao.ativo === "S" ? "Ativo" : "Inativo"
      };
    },
    async getDataTable(filters) {
      this.loading = true;
      const { data } = await campanha()
        .produtoModelo()
        .show({
          per_page: -1,
          ativo: "S",
          ...filters
        });
      this.items = data.data;
      this.loading = "false";
    },
    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "Deletar Produto Modelo",
          `Deseja deletar Produto Modelo ${item.descricao_modelo}?`
        );
        campanha()
          .produtoModelo(item.id_produto)
          .delete(
            {},
            {
              notification: true,
              menssage: "Produto Modelo deletada com sucesso!"
            }
          );
        this.refresh();
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao deletar Produlo Modelo"
        });
      } finally {
        this.getDataTable(this.filters);
      }
    }
  },
  async mounted() {
    await this.getDataTable();
  }
};
</script>
