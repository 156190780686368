<template>
  <v-menu :close-on-content-click="false" max-width="320" offset-x>
    <template v-slot:activator="{ on }">
      <FilterBottom v-on="on" />
    </template>
    <v-card class="pa-3">
      <v-container>
        <p>
          Status
        </p>
        <v-switch
          v-model="filters.ativo"
          true-value="S"
          false-value="N"
          :label="`${filters.ativo == 'S' ? 'Ativo' : 'Inativo'}`"
          @change="handleFilter()"
        >
        </v-switch>
        <BaseFilter
          prepend-inner-icon="mdi-file-table-box-multiple-outline"
          clearable
          single-line
          label="Segmento"
          item-text="descricao"
          item-value="id_band"
          service="sistemaService.tipoBandeiras"
          v-model="filters.id_band"
          :isCompany="true"
          @change="handleFilter()"
        />
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import FilterBottom from "@/components/shared/bottons/FilterBottom";
import BaseFilter from "@/components/shared/BaseSelectService";
export default {
  components: {
    FilterBottom,
    BaseFilter
  },
  data() {
    return {
      filters: { ativo: "S" }
    };
  },
  methods: {
    handleFilter() {
      this.$emit("selectedFilters", this.filters);
    }
  }
};
</script>

<style></style>
