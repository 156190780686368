<template>
  <v-container class="container-card">
    <v-card>
      <Tabela />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Tabela from "@/components/parametros/produtoModelo/Tabela";

export default {
  name: "ProdutoModelo",

  components: {
    Tabela
  },
  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Consulta Modelo");
  }
};
</script>
